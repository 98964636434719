import Vue from "vue";
import Vuex from "vuex";
import themer from "@xtreme-vue-utils/themer";
import authentication from "@/store/modules/authentication";

Vue.use(Vuex);

export const storeModel = {
    state: {
        config: {},
        lockerStates: [],
        users: [],
        availableLocations: [{ value: "bq-cardiff", text: "Cardiff" }],
    },
    mutations: {
        updateConfig(state, data) {
            state.config = data;
        },

        updateUsers(state, data) {
            state.users = data;
        },
    },
    actions: {
        subscribeToUsers(context) {
            context.dispatch("authentication/send", {
                type: "users",
                action: "get",
                meta: {
                    location: "*",
                },
            });
        },
        unSubscribeToUsers(context) {
            context.dispatch("authentication/send", {
                type: "users",
                action: "unsubscribe",
                meta: {
                    location: "*",
                },
            });
        },
    },
    modules: {
        authentication,
        themer,
    },
};

const store = new Vuex.Store(storeModel);

store.watch(
    (state) => state.themer.theme,
    (theme) => {
        store.state.analytics.dailyUsage.datasets[0].backgroundColor = theme.colors.primary;
        store.state.analytics.dailyUsage.datasets[1].backgroundColor = theme.colors.secondary;
    },
);

export default store;
