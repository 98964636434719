import XAuth from "@xtreme-controls/xtreme-authentication";

export const mutations = {
    updateXAuth(state, xAuth) {
        state.xAuth = xAuth;
    },
};

export const actions = {
    async login(context, loginPacket) {
        if (await context.state.xAuth.loggedIn()) {
            return true;
        }

        return context.state.xAuth.login(loginPacket.username, loginPacket.password);
    },

    async logout(context) {
        await context.state.xAuth.logout();
    },

    async send(context, msg) {
        if (!context.state.xAuth) {
            context.dispatch("initialise");
            await new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 750);
            });
        }
        return context.state.xAuth.send(msg);
    },

    getConfig(context) {
        const packet = {
            meta: {
                location: localStorage.getItem("location"),
            },
            type: "configurations",
            action: "get",
        };

        context.dispatch("send", packet);
    },

    receivedFeedback(context, msg) {
        switch (msg.type) {
            case "configurations":
                context.commit("updateConfig", msg.data[0], { root: true });
                break;
            case "users":
                context.commit("updateUsers", msg.data, { root: true });
                break;
            default:
                break;
        }
    },

    initialise(context) {
        if (!context.state.xAuth) {
            try {
                let wsUrl = `${window.location.hostname}/ws/app/`;
                if (process.env.VUE_APP_WS_SERVER_URL) {
                    wsUrl = process.env.VUE_APP_WS_SERVER_URL;
                }
                const xAuthInstance = new XAuth(wsUrl, { requireSmsVerification: true }, (msg) => {
                    context.dispatch("receivedFeedback", msg);
                });

                context.commit("updateXAuth", xAuthInstance);
                context.dispatch("getConfig");
            } catch (err) {
                console.error("xAuth Connection Error", err);
            }
        }
    },
};

export default {
    namespaced: true,
    state: () => ({
        xAuth: undefined,
    }),
    mutations,
    actions,
};
