/* eslint-disable no-console */

import { register } from "register-service-worker";

function updateSecviceWorker(reg) {
    reg.waiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
            window.location.reload();
        }
    });
    reg.waiting.postMessage({ type: "SKIP_WAITING" });
}

if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready() {
            console.info("App is being served from cache by a service worker.\nFor more details, visit https://goo.gl/AFskqB");
        },
        registered() {
            console.info("Service worker has been registered.");
        },
        cached() {
            console.info("Content has been cached for offline use.");
        },
        updatefound() {
            console.info("New content is downloading.");
        },
        updated(reg) {
            console.info("New content is available.");

            if (localStorage.getItem("swAwaitApproval") === "true") {
                console.info("Prompting user to update.");
                document.dispatchEvent(new CustomEvent("swUpdated"));
                document.addEventListener("swSkipWait", () => updateSecviceWorker(reg));
            } else {
                console.info("Automatically updating.");
                updateSecviceWorker(reg);
            }
        },
        offline() {
            console.info("No internet connection found. App is running in offline mode.");
        },
        error(error) {
            console.error("Error during service worker registration:", error);
        },
    });
}
