/* istanbul ignore file */

// Allowed meta keys:
// hideNavigation (boolean): default is false, true will hide
// requireAuth (boolean): default is false, true will force login
// accessLevel (int): will hide from menu and display 401 access denied if trying to access
// menu (object): with icon and text properties, if object exists it will be added to the menu (filtered by access level)
const routes = [
    {
        path: "/dashboard",
        alias: "/",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
        meta: {
            requiresAuth: true,
            menu: {
                icon: "mdi-chart-bar",
                text: "Dashboard",
            },
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/usermgmt",
        name: "usermgmt",
        component: () => import(/* webpackChunkName: "usermgmt" */ "../views/UserMgmt.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/registration",
        name: "registration",
        component: () => import(/* webpackChunkName: "registration" */ "../views/Registration.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/reset",
        name: "reset",
        component: () => import(/* webpackChunkName: "reset" */ "../views/Reset.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "settings" */ "../views/Settings.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 0,
            menu: {
                icon: "mdi-cog",
                text: "Settings",
            },
        },
    },
    {
        path: "/profile",
        name: "profile",
        component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
        meta: {
            requiresAuth: true,
            menu: {
                icon: "mdi-account",
                text: "Profile",
            },
        },
    },
    {
        path: "/profile/:uid?",
        name: "edituser",
        component: () => import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 2,
        },
    },
    {
        path: "/useradmin",
        name: "useradmin",
        component: () => import(/* webpackChunkName: "useradmin" */ "../views/UserAdmin.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: 2,
            menu: {
                icon: "mdi-account-group",
                text: "User Admin",
            },
        },
    },
    {
        path: "/MultiFactorAuth",
        name: "MultiFactorAuth",
        component: () => import(/* webpackChunkName: "multifactorauth" */ "../views/MultiFactorAuth.vue"),
        props: true,
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/401",
        name: "401",
        component: () => import(/* webpackChunkName: "401" */ "../views/errors/401.vue"),
        meta: {},
    },
    {
        path: "*",
        name: "404",
        component: () => import(/* webpackChunkName: "401" */ "../views/errors/404.vue"),
        meta: {},
    },
];

export default routes;
